import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-4"},[_c('ReqStats'),_c(VCard,{staticClass:"mx-auto mt-3",attrs:{"flat":""}},[_c(VRow,{staticClass:"md-justify-space-around mt-sm-2"},[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c(VToolbarTitle,{staticClass:"header-title"},[_vm._v(_vm._s(_vm.documentType))])],1)],1),_c(VCol,{staticClass:"d-flex",attrs:{"cols":"12","md":"4"}},[_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c(VBtnToggle,{attrs:{"active-class":"toggle--active","borderless":"","dense":"","mandatory":"","rounded":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.status),function(item,i){return _c(VBtn,{key:i,attrs:{"color":item === _vm.tabs ? 'primary' : 'default',"elevation":item === _vm.tabs ? 8 : 0,"rounded":item === _vm.tabs,"value":item,"small":""}},[_c('span',{staticClass:"text-capitalize",style:({ color: item === _vm.tabs ? '#ffffff' : '' })},[_vm._v(_vm._s(item === "Released" ? "Approved" : item && item === "Pending Approval" ? "Pending" : item))])])}),1)],1)],1),_c(VCol,{staticClass:"d-md-flex justify-end",attrs:{"cols":"12","md":"3","offset":"1"}},[_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c(VSpacer),_c(VBtn,{staticClass:"text-capitalize pt-1 d-sm-block d-md-table-cell",attrs:{"to":{
              name: 'RequisitionCard',
              params: { code: _vm.$route.params.documentType },
            },"color":"primary"}},[_c(VIcon,{staticClass:"mx-2"},[_vm._v("add")]),_c('span',{staticClass:"header-title--sub"},[_vm._v("New Request")])],1)],1)],1)],1),_c(VRow,[_c(VCol,{staticClass:"ml-md-4",attrs:{"cols":"12","md":"4","sm":"12"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"auto","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"color":"primary","dense":"","label":"Filter by Order Date","prepend-inner-icon":"filter_alt","clearable":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.filterDatePicker),callback:function ($$v) {_vm.filterDatePicker=$$v},expression:"filterDatePicker"}},[_c(VDatePicker,{attrs:{"range":""},on:{"click":function($event){(_vm.status = 'Open'), (_vm.imprestStatus = 'Issued')},"input":function($event){(_vm.filterDatePicker = false),
                (_vm.startDate = _vm.filterDate[0]),
                (_vm.endDate = _vm.filterDate[1])}},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}})],1)],1),_c(VSpacer),_c(VCol,{staticClass:"mr-md-4",attrs:{"cols":"12","md":"4","sm":"12"}},[_c(VTextField,{staticClass:"mt-n3",attrs:{"append-icon":"mdi-magnify","clearable":"","hide-details":"","label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.requisitions,"items-per-page":5,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"multi-sort":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"table-font"},[_vm._v(_vm._s(item.no))])]}},{key:"item.requestedFor",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"table-font"},[_vm._v(_vm._s(_vm.getEmployee(item.requestedFor)))])]}},{key:"item.dueDate",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"table-font"},[_vm._v(_vm._s(_vm.getDateFormat(item.dueDate)))])]}},{key:"item.orderDate",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"table-font"},[_vm._v(_vm._s(_vm.getDateFormat(item.orderDate)))])]}},(_vm.documentType !== 'Store Requisition')?{key:"item.amount",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"table-font"},[_vm._v(_vm._s(_vm.isMultiCurrencyEnabled ? ("(" + (item.currencyCode) + ")") : ("(" + _vm.defaultCurrencyCaption + ")"))+" "+_vm._s(_vm._f("currencyFormat")(item.amount)))])]}}:null,(_vm.documentType === 'Store Requisition')?{key:"item.quantity",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"table-font"},[_vm._v(_vm._s(_vm.getQuantity(item.requisitionLines)))])]}}:null,{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c(VIcon,{staticClass:"mx-1 mt-n1",attrs:{"color":_vm.getStatusColor(item.status).color,"label":"","small":""}},[_vm._v(" mdi-radiobox-marked ")]),_c('span',{staticClass:"table-font"},[_vm._v(_vm._s(item.status === "Released" ? "Approved" : item.status))])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mx-1",attrs:{"to":{
                    name: 'RequisitionCard',
                    params: { code: _vm.$route.params.documentType },
                    query: { code: item.no },
                  },"color":"teal lighten-4","label":"","small":""}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"teal darken-4","small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("view")])]),_c('div',{staticClass:"d-flex justify-start"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status === 'Open'),expression:"item.status === 'Open'"}],staticClass:"mx-1",attrs:{"color":"blue lighten-4","label":"","small":""},on:{"click":function($event){return _vm.sendListApprovalRequest(item.no)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"blue darken-4","small":""}},[_vm._v("mdi-send")])],1)]}}],null,true)},[_c('span',[_vm._v("send")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status === 'Open'),expression:"item.status === 'Open'"}],staticClass:"mx-1",attrs:{"color":"red lighten-4","label":"","small":""},on:{"click":function($event){return _vm.deleteRequisition(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"red darken-4","small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("remove")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status === 'Pending Approval'),expression:"item.status === 'Pending Approval'"}],staticClass:"mx-1",attrs:{"color":"orange lighten-4","label":"","ripple":"","small":""},on:{"click":function($event){return _vm.cancelRequisition(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"orange darken-4","small":""}},[_vm._v("cancel_schedule_send")])],1)]}}],null,true)},[_c('span',[_vm._v("cancel request")])])],1)],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }